<template>
  <div id="box">
    <search @submit="submitSearch" />

    <a-row :gutter="24">
      <a-col span="4">
        <div class="left-menu">
          <div
            v-for="productType in this.productTypeOptions"
            :key="productType.value"
            :class="activeType === productType.value ? 'type-box type-box-active': 'type-box'"
            @click="handleChangeProductType(productType.value)"
          >
            <img :src="activeType === productType.value ? `/temp/${productType.value}_active.png` : `/temp/${productType.value}.png`" alt="logo">
            <span>{{ productType.name }}</span>
          </div>
        </div>
      </a-col>
      <a-col span="20" class="product-box" id="product-box" @scroll="resetBackToHall">
        <div class="product" v-if="products && products.length > 0">
          <div
            v-for="(product, stateIndex) in products"
            :key="stateIndex"
            class="product-content-box"
          >
            <div class="product-content">
              <img
                v-show="product.images && product.images.length > 0"
                class="product-img"
                alt="img"
                :src="product.images != null ? product.images[0].url : ''"
              >
              <img
                v-show="!product.images || product.images.length === 0"
                class="product-img"
                alt="img"
                src="/temp/no_picture.png"
              >
              <div class="product-name custom-word-break-all">
                {{ product.name }}
              </div>
              <div class="custom-flex custom-justify-between">
                <div class="price">
                  {{ product.price }}
                </div>
                <div class="sub-button" @click="showSubscribeModal(product.id)">订购</div>
              </div>
            </div>
          </div>
        </div>
        <div class="no-data" v-else>
          <img src="/temp/mobile_no_data.png" alt="logo">
          <span>暂无数据</span>
        </div>
      </a-col>
    </a-row>

    <subscribe
      v-if="isShowSubscribeModal"
      :visible.sync="isShowSubscribeModal"
      :id="subscribeProductId"
      :service-order-id="serviceOrderId"
      @completed="fetchData"
    />
  </div>
</template>

<script>
import { findProducts } from '@/api/product'
import Search from '@/views/products/mobile/Search'

export default {
  name: 'MobileProductIndex',
  components: {
    Search,
    Subscribe: () => import('@/views/products/Subscribe')
  },
  data() {
    return {
      query: {},
      productType: '',
      products: [],
      isShowSubscribeModal: false,
      subscribeProductId: 0,
      activeType: 'all'
    }
  },
  computed: {
    serviceOrderId() {
      return parseInt(this.$route.query.service_order_id)
    },

    hallId() {
      return parseInt(this.$route.query.hall_id)
    },

    productTypeOptions() {
      return [
        { name: '全部', value: 'all' },
        { name: '寿服', value: 'shroud' },
        { name: '寿棺', value: 'coffin' },
        { name: '灵盒', value: 'urn' },
        { name: '鲜花', value: 'wreath' },
        { name: '殡葬用品', value: 'once' },
        { name: '答谢礼', value: 'gift' },
        { name: '衍生服务', value: 'derivation' }
      ]
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    resetBackToHall() {
      this.$emit('resetBackToHall')
    },

    fetchData() {
      const data = {
        ...this.query,
        product_type: this.productType !== 'all' ? this.productType : ''
      }
      findProducts(data).then((res) => {
        if (res.code === 0) {
          this.products = res.data.data
        }
      })
    },

    handleChangeProductType(productType) {
      this.activeType = productType
      this.productType = productType
      this.fetchData()
    },

    showSubscribeModal(id) {
      this.isShowSubscribeModal = true
      this.subscribeProductId = id
    },

    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        search)
      this.fetchData()
    }
  }
}
</script>

<style lang="less" scoped>
.breadcrumb {
  margin-bottom: 20px;
  font-size: 20px;
}

.left-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 1600px;
  background-color: @background-color;

  .type-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 40px 0;
    width: 100%;

    img {
      width: 60px;
    }

    span {
      font-size: 20px;
    }
  }

  .type-box-active {
    color: #ffd794ff;
  }
}

.product-box {
  overflow: auto;
  height: 1600px;
}

.product {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  .product-content-box {
    flex-basis: 50%;
  }

  .product-content {
    display: flex;
    padding: 0 40px 40px;
    flex-direction: column;

    .product-img {
      width: 320px;
      height: 250px;
      border-radius: 5px;
    }

    .product-name {
      margin-top: 10px;
      font-size: 20px;
      color: @white;
    }

    .price {
      margin-top: 10px;
      font-size: 24px;
      font-weight: bold;
      color: #ffd794ff;
    }

    .sub-button {
      margin-top: 10px;
      width: 70px;
      height: 35px;
      font-size: 20px;
      border: 1px solid;
      border-radius: 50px;
      text-align: center;
      color: #ffd794b0;
      cursor: pointer;
    }
  }
}

.no-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100%;

  img {
    width: 100px;
  }

  span {
    margin-top: 10px;
    font-size: 20px;
  }
}
</style>

